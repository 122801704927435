import React from "react";

function CloudSolutions() {
  return (
    <div>
      <h1>Cloud Solutions</h1>
      <p>Here, you can display some context about this project.</p>
      <img src="path-to-your-screenshot.jpg" alt="Screenshot of Cloud Solutions"/>
      <a href="https://example.com" target="_blank" rel="noopener noreferrer">Link to Project</a>
    </div>
  );
}

export default CloudSolutions;
